import { HttpClient } from '@angular/common/http';
import { EventEmitter, Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';

export interface ITokenDetails {
  /** Ссылка на страницу  сервиса партнёра , на которую необходимо вернуть пользователя  после успеха */
  successUrl: string;
  /** Ссылка на страницу сервиса партнёра, на которую необходимо перенаправить пользователя  в случае ошибки */
  failUrl: string;
  /** Ссылка на страницу сервиса партнёра, на которую необходимо перенаправить пользователя  в случае протухания токена */
  timeoutUrl: string;
  /** Идентификатор клиента */
  clientId: string;
  serviceType: PROJECT_TYPE;
  exp: number;
}

export enum PROJECT_TYPE {
  HOTEL = 'hotel',
  AIR = 'air',
  TRAIN = 'train',
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public tokenDetails: ITokenDetails;
  public onTimeout = new EventEmitter();

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _injector: Injector,
  ) {}

  public isAuthorized(): boolean {
    return this.tokenDetails !== null;
  }
  // {guid: string, clientId: string, successTenantUrl: string, failTenantUrl: string}
  public async auth(query: any) {
    let token: string = null;
    try {
      token = await this._http.get(`v1/token?guid=${query.guid}`,  {responseType: 'text'}).toPromise();
    } catch (e) {
      this._router.navigate(['/error']);
      return false;
    }
    this.setToken(token);
    this.registerSessionTimeout();
    if (!this.tokenDetails.serviceType) {
      this.tokenDetails.serviceType = PROJECT_TYPE.AIR;
    }
    this._router.navigate(['/air-search']);
    return true
  }

  public loadTokenIfExists(): boolean {
    let token = window.localStorage.getItem(`token`);
    if (token) {
      this.tokenDetails = jwtDecode(token);
      if (!this.tokenDetails.serviceType) {
        this.tokenDetails.serviceType = PROJECT_TYPE.AIR;
      }
      try {
        this.registerSessionTimeout();
      } catch (e) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }

  public getToken(): string {
    return window.localStorage.getItem(`token`) || null;
  }

  public registerSessionTimeout() {
    let secondsToTimeout = moment(this.tokenDetails.exp * 1000).diff(moment(), 'seconds');
    console.info('seconds to timeout: ' + secondsToTimeout);
    if (secondsToTimeout < 0) {
      throw new Error('expired token');
    } else if (secondsToTimeout * 1000 > Math.pow(2, 32)) {
      console.log('too BIG value to timeout');
    } else {
      setTimeout(this.onTimeout.emit.bind(this.onTimeout), secondsToTimeout * 1000);
    }
  }

  public setToken(token) {
    this.tokenDetails = jwtDecode(token);
    window.localStorage.setItem(`token`, token);
  }

  public removeToken(redirect?: string) {
    window.localStorage.removeItem(`token`);
    this.tokenDetails = null;
    if (!!redirect) window.location.href = redirect;
  }
}
