import { Injectable } from "@angular/core";
import { IAirSearchModal } from "@app/air-module/containers/air-search/air-search.component";
import { ComponentStore } from "@ngrx/component-store";
import { IPriceData } from "../interfaces/price-data.interface";
import { Price } from "../class/price";
import { IHotelSearchModal } from "@app/hotel-module/hotel-search/hotel-search.component";
import { IHotelCardRate, IHotelCardRoom } from "../interfaces/hotel-card.interface";
import { IHotel } from "../interfaces/hotel.interface";
import { IGuestModel } from "../interfaces/hotel-book.interface";

export interface ISelectAirRate {
  rate: Price;
  airFlight: IPriceData;
}

export interface ISelectHotelRate {
  rate: IHotelCardRate;
  room: IHotelCardRoom;
  hotel: IHotel
}

export interface SearchState {
  searchForm: IAirSearchModal | IHotelSearchModal;
  isSearchEmpty: boolean;
  selectAirRate: ISelectAirRate;
  selectHotelRate: ISelectHotelRate[];
  guests: IGuestModel[]
}

@Injectable({
  providedIn: 'root',
})
export class SearchStore extends ComponentStore<SearchState> {
  constructor() {
    super({
      searchForm: null,
      isSearchEmpty: false,
      selectAirRate: null,
      selectHotelRate: [],
      guests: [],
    });
  }

  // --------- getters ----------------
  get searchForm() {
    return this.get().searchForm;
  }

  get isSearchEmpty() {
    return this.get().isSearchEmpty;
  }

  get selectAirRate() {
    return this.get().selectAirRate;
  }

  get selectHotelRate() {
    return this.get().selectHotelRate;
  }

  get guests() {
    return this.get().guests;
  }

  // --------- selectors ---------------
  readonly searchForm$ = this.select((state) => state.searchForm);
  readonly isSearchEmpty$ = this.select((state) => state.isSearchEmpty);

  // ------------ updaters ---------------
  public readonly resetAll = this.updater((state) => ({
    ...state,
    searchForm: null,
    isSearchEmpty: false,
    selectAirRate: null,
    selectHotelRate: [],
    guests: [],
  }));

  public readonly resetHotelRate = this.updater((state) => ({
    ...state,
    selectHotelRate: [],
  }));

  public readonly resetGuest = this.updater((state) => ({
    ...state,
    guests: [],
  }));

  public readonly setSearchForm = this.updater(
    (state, data: IAirSearchModal | IHotelSearchModal) => ({
      ...state,
      searchForm: data,
    })
  );

  public readonly setSearchEmptyStatus = this.updater((state, isSearchEmpty: boolean) => ({
    ...state,
    isSearchEmpty,
  }));

  public readonly setAirRate = this.updater((state, data: ISelectAirRate) => ({
    ...state,
    selectAirRate: data,
  }));

  public readonly setHotelRate = this.updater((state, data: ISelectHotelRate) => ({
    ...state,
    selectHotelRate: [...state.selectHotelRate, data],
  }));

  public readonly setGuest = this.updater((state, data: IGuestModel) => ({
    ...state,
    guests: [...state.guests, data],
  }));
}
